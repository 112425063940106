import RequestAdmin from '@/utils/request'
import config from '@/utils/config'
import { formatDate } from '@/utils/fechas'

const montaje = [
  {
    id: 1,
    nombre: 'Cable',
    value: 'cable',
  },
  {
    id: 2,
    nombre: 'Brazo',
    value: 'brazo',
  },
  {
    id: 3,
    nombre: 'Lámpara',
    value: 'lampara',
  },
]

const url = `${config.URL_BASE}aplomados`
export async function updateCreateAplomado(body, tipo) {
  try {
    const request = new RequestAdmin()
    if (tipo === 1) {
      return request.executePostPutPatch(`${url}/${body.id}`, body, 'PATCH')
    }
    // eslint-disable-next-line no-param-reassign
    delete body.id
    return request.executePostPutPatch(`${url}`, body, 'POST')
  } catch (err) {
    console.error('Error en Crear / Editar Gateway', err)
    return null
  }
}

export async function findAplomado(filter) {
  try {
    const request = new RequestAdmin()
    const urrl = `${url}?filter=${JSON.stringify(filter)}`
    return request.executeGet(urrl)
  } catch (err) {
    console.error('Error en obtener Tickets', err)
    return null
  }
}

// eslint-disable-next-line import/prefer-default-export
export async function getAplomado(id) {
  try {
    if (id === null || id === undefined) return null
    const request = new RequestAdmin()
    return await request.executeGet(`${url}/${id}`)
  } catch (err) {
    console.error('Error en cargar Ticket', err)
    return null
  }
}

// eslint-disable-next-line import/prefer-default-export
export function getListadoAplomadoPoste(tipo) {
  if (tipo === 1) return montaje
  return []
}

function getClassRotate(item) {
  if (item.isMobile) return 'rotate_image'
  return 'no_rotate_image'
}

export async function generarReporte(aplomado) {
  function obtenerTrabajosMontaje(row) {
    // Obtener los nombres de los trabajos de montaje de la fila y devolverlos como una cadena de texto
    const nombresMontaje = row.trabajosMontaje.map(trabajo => trabajo.nombre)
    return nombresMontaje.join(', ')
  }

  function obtenerTrabajosDesmontaje(row) {
    // Obtener los nombres de los trabajos de desmontaje de la fila y devolverlos como una cadena de texto
    const nombresDesmontaje = row.trabajosDesmontaje.map(trabajo => trabajo.nombre)
    return nombresDesmontaje.join(', ')
  }
  const html = `
    <!DOCTYPE html>
    <html lang="es">
<head>
  <meta charset="utf-8">
  <meta name="factura" content="width=device-width, initial-scale=1">
  <title>REPORTE DE APLOMADO DE POSTES</title>
  <meta charset="utf-8">
</head>
<style>
    @page {
      margin-top: 1.1811in;
      margin-bottom: 0.7874in;
      margin-left: 0.7874in;
      margin-right: 0.7874in;
    }
    .headLogos{
        width: 100%;
        margin-bottom: 5px;
        border-collapse: collapse;
    }
    .letrasLogo{
        background-color: #1f88c2;
        color: white;
        font-family: sans-serif;
    }
    .tabla{
        border-collapse: collapse;
        width: 100%;
        font-family: sans-serif;
    }
    .tituloDatos {
        background-color: #1f88c2;
        color: yellow;
        font-family: sans-serif;
        padding: 3px;
        width: 300px;
    }
    .tituloDetalle {
        background-color: #1f88c2;
        color: yellow;
        font-family: sans-serif;
        padding: 3px;
    }
    th{
        border: 1px solid black;
        font-family: sans-serif;
    }
    td{
        font-family: sans-serif;
        padding-right: 15px;
        padding-left: 15px;
    }
    .Imagenes{
        width: 100%;
        border-collapse: collapse;
    }
    .table {
        border: 1px solid black;
        border-collapse: collapse;
        width: 100%;
        padding-right: 50px;
    }
    .lineaFirma2 {
        border-top: 1px solid black;
        margin-left: auto;
        margin-right: auto;
        width: 40%;
    }
    .lineaFirma3 {
        border-top: 1px solid black;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
    .lineaFirma{
        border-top: 1px solid black;
        width: 25%;
        display: flex;
        margin-top: 150px;
        font-family: arial,serif;
    }
    .linea {
        position: relative;
        margin-top: 40px;
        width: 100%;
    }

    .linea hr {
        border: none;
        border-top: 1px solid black;
        margin-bottom: 15px;
    }
    .texto {
        text-transform: uppercase;
        text-align: center;
        font-family: arial,serif;
    }
    .centro {
        display: flex;
    }
    .linea span {
        width: 80%;
        position: absolute;
        top: -15px;
        transform: translate(-50%, -50%);
    }
    .no_rotate_image {
        min-width: 180px;
        min-height: 180px;
        max-width: 350px;
        max-height: 300px;
    }
    .rotate_image {
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        min-width: 180px;
        min-height: 180px;
        width: 300px;
        height: 350px;
    }
</style>
<body>
<table class="headLogos">
  <tr>
    <th width="66.64%" height="20px" class="letrasLogo"> <center>Reporte de Aplomado de Poste<br>Dirección de Servicios Públicos<br>Municipalidad de Villa Nueva</center></th>
    <th width="33.33%" height="20px">
      <img style="max-width: 180px;" src="https://storage.googleapis.com/apvn-files/imagenes/inspecciones/logo-apvn.jpg" alt="logoapvn"/>
    </th>
  </tr>
</table>
<table style="border-collapse: collapse">
  <tr>
    <th class="tituloDatos">INFORMACIÓN GENERAL</th>
  </tr>
</table>
<table class="table">
  <tr>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">NÚMERO DE POSTE</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 25%; text-align: center; text-transform: uppercase; font-size:10.0pt">${aplomado.poste}</span>
      </div>
    </td>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">BRIGADA</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 25%;text-align: center; text-transform: uppercase; font-size:10.0pt">${aplomado.brigada.nombre}</span>
      </div>
    </td>
  </tr>
  <tr>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">LUGAR</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 20%; text-align: center; text-transform: uppercase; font-size:10.0pt;">${aplomado.lugar}</span>
      </div>
    </td>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">FECHA</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 25%; text-align: center; text-transform: uppercase; font-size:10.0pt">${formatDate(aplomado.fechaCreacion, '/')}</span>
      </div>
    </td>
  </tr>
</table>
<br>
  <table class="table" style="border-collapse: collapse">
    <tr>
      <th class="tituloDatos">TRABAJO(S) MONTAJE</th>
      <th class="tituloDatos">TRABAJO(S) DESMONTAJE</th>
    </tr>
  </table>
<table class="table">
  <tr>
    <td>
      <div class="linea">
      <div style="text-align:center; text-transform: uppercase; font-size:10.0pt">${obtenerTrabajosMontaje(aplomado)}</div>
        <hr>
      </div>
    </td>
    <td>
      <div class="linea">
      <div style="text-align:center; text-transform: uppercase; font-size:10.0pt">${obtenerTrabajosDesmontaje(aplomado)}</div>
        <hr>
      </div>
    </td>
  </tr>
</table>
<br>
<table class="table" style="border-collapse: collapse">
    <tr>
      <th class="tituloDatos">APLOMADO DE POSTE</th>
    </tr>
  </table>
<table class="table">
  <tr>
    <td>
      <div class="linea">
      <div style="text-align:center; text-transform: uppercase; font-size:10.0pt">${aplomado.aplomadoPoste}</div>
        <hr>
      </div>
    </td>
    </tr>
    </table>
<br>
<table class="table" style="border-collapse: collapse">
    <tr>
      <th class="tituloDatos">COMENTARIOS DEL DESMONTAJE</th>
    </tr>
  </table>
<table class="table">
  <tr>
    <td>
      <div class="linea">
      <div style="text-align:center; text-transform: uppercase; font-size:10.0pt">${aplomado.seguimiento.length >= 2 ? aplomado.seguimiento[1].comentarios : ''}</div>
        <hr>
      </div>
    </td>
    </tr>
    </table>
    <br>
    <table class="table" style="border-collapse: collapse">
    <tr>
      <th class="tituloDatos">COMENTARIOS DEL DESPUÉS</th>
    </tr>
  </table>
<table class="table">
  <tr>
    <td>
      <div class="linea">
      <div style="text-align:center; text-transform: uppercase; font-size:10.0pt">${aplomado.seguimiento.length >= 3 ? aplomado.seguimiento[2].comentarios : ''}</div>
        <hr>
      </div>
    </td>
    </tr>
    </table>
<table class="Imagenes">
  <tr>
    <th class="tituloDatos">Fotografía Panorámica del Antes</th>
    <th class="tituloDatos">Fotografía Panorámica del Desmontaje</th>
    <th class="tituloDatos">Fotografía Panorámica del Después</th>
  </tr>
  <tr>
    <th><img src="${aplomado.seguimiento[0].url}" alt="img_0" width="400" height="400"></th>
    <th><img src="${aplomado.seguimiento[1].url}" alt="img_1" width="400" height="400"></th>
    <th><img src="${aplomado.seguimiento[2].url}" alt="img_2" width="400" height="400"></th>
  </tr>
</table>
<br>
<br>
<br>
<br>
<div>
    <hr class="lineaFirma2">
  </div>
  <div class="texto">
    <p><br>ALUMBRADO PÚBLICO DE VILLA NUEVA, S.A.</p>
  </div>
</div>
</body>
</html>
  `

  const body = {
    tamaño: 'A2',
    margenes: {
      top: '2cm',
      right: '2cm',
      bottom: '2cm',
      left: '2cm',
    },
    orientation: 'portrait',
    contenido: html,
  }
  const request = new RequestAdmin()
  const result = await request.executePostPutPatch('https://api.apsistema.com/documentos', body, 'POST')
  return result
}
